import React from "react"
import Layout from "../components/layout"

import ConsultationCta from "../components/ConsultationCta"
import ResourceCard from "../components/ResourceCard"
import ResourceRequest from "../components/ResourceRequest"
import { Link } from "gatsby"

import ResourcePagination from "../components/ResourcePagination"
import SEO from "../components/seo"

const Resources = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <SEO title="Resources" />

      <div className="header resources__header">
        <div className="container grid">
          <div className="header-content header-content--wide">
            <h1 className="resources__title">
              Industry experience to guide you
            </h1>
          </div>
        </div>

        <div className="container header__buttons">
          <Link
            to="/case-studies"
            className="header__button button button--beige button--arrow-right"
          >
            Case Studies
          </Link>
          <Link
            to="/blog"
            className="header__button button button--beige button--arrow-right"
          >
            Blog
          </Link>
          {/* <Link
            to="/tools"
            className="header__button button button--beige button--arrow-right"
          >
            Tools
          </Link> */}
        </div>
      </div>

      <div className="resources-list container">
        {data.allMarkdownRemark.nodes.map(blog => {
          return (
            <ResourceCard
              key={blog.id}
              slug={blog.frontmatter.slug}
              thumbnail={blog.frontmatter.thumbnail}
              title={blog.frontmatter.title}
              category={blog.frontmatter.type}
              tags={blog.frontmatter.tags}
            />
          )
        })}

        {/* <ResourceRequest /> */}
      </div>

      {/* <ResourcePagination
        basePath="/resources"
        pages={[1, 2, 3, 4, 5]}
        nextPage={2}
      /> */}

      <ConsultationCta />
    </Layout>
  )
}

export default Resources
export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___published] }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          tags
          type
          slug
          published
          thumbnail {
            childImageSharp {
              fluid(maxHeight: 168, maxWidth: 336) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
